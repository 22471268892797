import React from 'react';
import './css/error.scss';


function Error () {
    return (
        <div>
            <h1>404 Not Found</h1>
            <a href={"/"}>go back to main page</a>
        </div>
    )
}

export default Error